import {environment} from "@/auth/environment";
import axios from "axios";

export default class UnloggedService {


    constructor() {
    }

    download_attachment(token, md5, data) {
        return axios.get(environment.baseURL + "/documents/download/" + token + "/" + md5);

    }


    check_attachment_password(token, md5, data) {

        return axios.get(environment.baseURL + "/documents/check_attachment_password/" + token + "/" + md5);

    }


}

<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
          :src="appLogoImage"
          alt="logo"
      />
      <h2 class="brand-text text-primary ml-1">
        BBM
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center" v-if="!downloadStarted">
        <h2 class="mb-1">
          {{ $t("Password required") }}
        </h2>
        <p class="mb-3">
          {{ $t("Please input the password received to download the file that has been shared with you") }}.
        </p>

        <!-- form -->
        <b-form
            inline
            class="row justify-content-center m-0 mb-2"
            @submit.prevent
        >
          <b-form-input
              id="notify-email"
              class="col-12 col-md-5 mb-1 mr-md-2"
              type="password"
              :placeholder="$t('Password')"
              v-model="password"
              :state="passwordValidation()"
          />


          <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              type="submit"
              @click="checkPassword()"
          >
            {{ $t("Proceed") }}
          </b-button>
        </b-form>
      </div>
        <div class="w-100 text-center" v-if="downloadStarted">
          <h2 class="mb-1">
            {{ $t("Thanks for downloading!") }}
          </h2>
          <p class="mb-3">
            {{ $t("Your download will begin automatically. If it didn’t start") }},
            <a ref="downloadLink" :href="link" download="1">{{ $t("click here")}}.</a>
          </p>
        </div>
        <b-img
            fluid
            :src="imgUrl"
            alt="Coming soon page"
        />

    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg, BFormValidFeedback, BFormInvalidFeedback
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {$themeConfig} from "@themeConfig";
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import UnloggedService from "@/basic/unloggedService";
import MD5 from "crypto-js/md5";
import {environment} from "@/auth/environment";

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
    BFormValidFeedback,
    BFormInvalidFeedback
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      password: "",
      isPasswordValid: null,
      downloadStarted: false,
      link: "",
      token: this.$route.params.token,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    passwordValidation() {
      if (this.password.length === 0)
        return false
      return this.isPasswordValid
    },
    checkPassword() {
      if (this.password.length > 0){
        const userService = new UnloggedService()
        const md5 = MD5(this.password).toString()
        userService.check_attachment_password(this.token, md5).then(response => {
          console.log('response check', response)
          if (response.data.status === "success") {

            this.isPasswordValid = true
            this.downloadStarted = true
            this.link = environment.baseURL + "/documents/download/" + this.token + "/" + md5
            this.$nextTick(() => {
              console.log("refs",this.$refs)
              console.log("downloadLink",this.$refs.downloadLink)
              const link_element = this.$refs.downloadLink
              link_element.click();

            })

          } else {
            this.isPasswordValid = false

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Password missmatch'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t('Please check the password and retry'),
              },
            })
          }
        })

      }

    }
  },
  setup() {
    // App Name
    const {appName, appLogoImage} = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
